<template>
  <div id="issueInfoDialog-displayScreen">
    <div class="row" v-for="(item, index) of rowInfo" :key="index">
      <div class="row-top">
        <strong>第{{ index + 1 }}行</strong>
        <el-select
          :disabled="(index === 0 || index === 1) && screenType === 'LCD'"
          ref="selectTag"
          class="select-tag"
          popper-class="select-tag-popper"
          :is-disabled="
            rowInfo[index].colInfo[0] === 17 ||
              rowInfo[index].colInfo.length >= 2
          "
          :popper-append-to-body="false"
          v-model="rowInfo[index].colInfo"
          multiple
          filterable
          clearable
          placeholder="请选择显示属性"
          @change="colInfoChange(index, $event)"
        >
          <el-option-group label="请选择显示属性">
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-option-group>
        </el-select>
        <el-select
          :disabled="(index === 0 || index === 1) && screenType === 'LCD'"
          v-model="rowInfo[index].textColor"
          filterable
          placeholder="请选择显示颜色"
          @change="$forceUpdate()"
        >
          <el-option-group label="请选择显示颜色">
            <el-option
              v-for="item in textColorList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-option-group>
        </el-select>
        <el-select
          :disabled="(index === 0 || index === 1) && screenType === 'LCD'"
          v-model="item.rollMode"
          filterable
          placeholder="请选择滚动模式"
          @change="$forceUpdate()"
        >
          <el-option-group label="请选择滚动模式">
            <el-option
              v-for="item in rollModeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-option-group>
        </el-select>
        <el-select
          :disabled="(index === 0 || index === 1) && screenType === 'LCD'"
          v-model="item.rollSpeed"
          filterable
          placeholder="请选择滚动速度"
          @change="$forceUpdate()"
        >
          <el-option-group label="请选择滚动速度">
            <el-option
              v-for="item of rollSpeedList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-option-group>
        </el-select>
      </div>
      <div class="row-bottom" v-if="rowInfo[index].colInfo[0] === 17">
        <el-input
          v-model="rowInfo[index].customMessage"
          clearable
          placeholder="请输入自定义内容"
          @input="$forceUpdate()"
        ></el-input>
      </div>
    </div>

    <el-button type="primary" @click="confirm">确认下发</el-button>
  </div>
</template>

<script>
export default {
  props: ["deviceRegisterId", "issueType", "typeList", "screenType"],
  data() {
    return {
      rowInfo: [],
      textColorList: [
        {
          label: "绿色",
          value: "绿色",
        },
        {
          label: "红色",
          value: "红色",
        },
        {
          label: "黄色",
          value: "黄色",
        },
      ],
      rollModeList: [
        {
          label: "不滚动",
          value: "不滚动",
        },
        {
          label: "左右滚动",
          value: "左右滚动",
        },
        {
          label: "上下翻页滚动",
          value: "上下翻页滚动",
        },
        {
          label: "截取（超出部分直接截断显示）",
          value: "截取（超出部分直接截断显示）",
        },
      ],
      rollSpeedList: [
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "5",
          value: "5",
        },
      ],
      loading: false,
    };
  },

  methods: {
    colInfoChange(index, tags) {
      if (tags.find((item) => item === 17)) {
        this.rowInfo[index].colInfo = [17];
        this.$refs.selectTag[index].blur();
      }
      if (tags.length >= 2) {
        this.rowInfo[index].colInfo = this.rowInfo[index].colInfo.slice(0, 2);
        this.$refs.selectTag[index].blur();
      }
      this.$forceUpdate();
    },
    async confirm() {
      const index = this.screenType === "LCD" ? 2 : 0;
      let list = [];
      for (let i = index, len = 4; i < len; i++) {
        const item = this.rowInfo[i];
        if (item.colInfo.length) {
          const colInfo = [];
          for (let j = 0, jLen = item.colInfo.length; j < jLen; j++) {
            colInfo.push({
              colIndex: j,
              customMessage: item.colInfo[j] === 17 ? item.customMessage : null,
              issueInfo: item.colInfo[j],
            });
          }

          list.push({
            rowIndex: i,
            colInfo,
            rollMode: item.rollMode,
            rollSpeed: item.rollSpeed,
            textColor: item.textColor,
          });
        }
      }

      try {
        this.loading = true;
        let res = await this.$http.post("/device/register/issue/edit", {
          deviceRegisterId: this.deviceRegisterId,
          issueType: this.issueType,
          rowInfo: list,
        });
        if (res.code === 0) {
          this.$message.success("操作成功");
          this.$emit("success");
        } else {
          this.$message.error(res.msg);
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#issueInfoDialog-displayScreen {
  .row {
    margin-bottom: 20px;

    .row-top {
      display: flex;
      align-items: center;
      strong {
        flex-shrink: 0;
        display: inline-block;
        width: 40px;
        margin-right: 10px;
        white-space: nowrap;
      }

      .el-select {
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
      }
      .select-tag {
        .el-input__inner {
          width: 230px;
        }
        &[is-disabled="true"] {
          .el-select-dropdown__item {
            color: #c0c4cc;
            cursor: not-allowed;
          }
          .selected {
            color: $main-color;
          }
        }
      }
    }
    .row-bottom {
      width: 100%;
      margin-top: 10px;
      display: flex;
      justify-content: flex-end;
      .el-input {
        width: calc(100% - 50px);
      }
    }
  }
  .el-button {
    width: 100%;
  }
}
</style>
