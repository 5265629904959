<template>
  <div id="issueInfoDialog">
    <el-dialog
      title="设备下发信息"
      :visible.sync="show"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @open="getTypeList"
      @closed="activeName = '0'"
    >
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
        v-loading="loading"
      >
        <el-tab-pane label="普通状态" name="0">
          <displayScreen
            ref="displayScreen0"
            :deviceRegisterId="deviceRegisterId"
            :typeList="typeList"
            :screenType="screenType"
            :issueType="activeName"
            @success="success"
          />
        </el-tab-pane>
        <el-tab-pane label="过车显示" name="1">
          <displayScreen
            ref="displayScreen1"
            :deviceRegisterId="deviceRegisterId"
            :typeList="typeList"
            :screenType="screenType"
            :issueType="activeName"
            @success="success"
          />
        </el-tab-pane>
        <el-tab-pane label="语音播报" name="2">
          <voiceBroadcast
            ref="voiceBroadcast"
            :deviceRegisterId="deviceRegisterId"
            :typeList="typeList"
            :screenType="screenType"
            :issueType="activeName"
            @success="success"
          />
        </el-tab-pane>
        <el-tab-pane label="播放设置" name="3" v-if="screenType === 'LCD'">
          <playback
            ref="playback"
            :deviceRegisterId="deviceRegisterId"
            @success="success"
          />
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>
<script>
import displayScreen from "./displayScreen.vue";
import voiceBroadcast from "./voiceBroadcast.vue";
import playback from "./playback.vue";
export default {
  props: ["getList"],
  components: { displayScreen, voiceBroadcast, playback },
  data() {
    return {
      show: false,
      loading: false,
      deviceRegisterId: null,
      screenType: "",
      info: null,
      typeList: [],
      activeName: "0",
    };
  },
  methods: {
    async getInfo(issueType) {
      try {
        this.loading = true;
        let res = await this.$http.post("/device/register/issue/info", {
          deviceRegisterId: this.deviceRegisterId,
          issueType,
        });
        if (res.code === 0) {
          if (this.activeName === "0" || this.activeName === "1") {
            const rowInfo = [];
            for (let i = 0; i < 4; i++) {
              const item = res.data?.rowInfo.find(
                (item) => item.rowIndex === i
              );
              let customMessage = "";
              let issueInfo_error = false;
              if (item) {
                for (let row of item.colInfo) {
                  if (row.issueInfo === 17) {
                    customMessage = row.customMessage;
                  }
                  if (
                    !this.typeList.find((data) => data.value === row.issueInfo)
                  ) {
                    issueInfo_error = true;
                  }
                }
              }
              rowInfo[i] = {
                rowIndex: i,
                colInfo:
                  item && !issueInfo_error
                    ? item.colInfo.map((item) => item.issueInfo)
                    : [],
                customMessage,
                rollMode: item ? item.rollMode : "不滚动",
                rollSpeed: item ? item.rollSpeed : "1",
                textColor: item ? item.textColor : "红色",
              };
            }
            this.$refs[`displayScreen${this.activeName}`].rowInfo = rowInfo;
          } else if (this.activeName === "2") {
            const issueInfoList = [];
            const list = [];
            let customMessage = "";
            if (res.data) {
              for (let item of res.data.rowInfo) {
                if (item.colInfo[0].issueInfo === 17) {
                  customMessage = item.colInfo[0].customMessage;
                }
                issueInfoList.push(item.colInfo[0].issueInfo);
              }
            }

            for (let item of issueInfoList) {
              const label = this.typeList.find(
                (data) => data.value === item
              )?.label;

              label && list.push(label);
            }

            this.$refs.voiceBroadcast.broadcastList = list;
            this.$refs.voiceBroadcast.draggableList = list;
            this.$refs.voiceBroadcast.customMessage = customMessage;
          }
        } else {
          this.$message.error(res.msg);
        }
        this.loading = false;
      } catch (err) {
        this.$message.error(err);
        this.loading = false;
      }
    },
    async getTypeList() {
      try {
        let res = await this.$http.post(`/device/register/issue/type`, {
          deviceRegisterId: this.deviceRegisterId,
          issueType: this.activeName,
        });
        const list = [];
        for (const key in res.data) {
          list.push({
            label: res.data[key],
            value: parseInt(key),
          });
        }
        if (res.code === 0) {
          this.typeList = list;
          this.getInfo(this.activeName);
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    success() {
      this.getList();
      this.show = false;
    },
    handleClick() {
      if (this.activeName === "3") {
        this.$refs.playback.getList();
      } else {
        this.getTypeList();
      }
    },
  },
};
</script>
<style lang="scss">
#issueInfoDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 730px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-tabs__item {
          color: #32373f;
        }
      }
    }
  }
}
</style>
