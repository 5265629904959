<template>
  <div id="addVehicleDialog">
    <el-dialog
      title="设备信息编辑"
      :visible.sync="show"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @open="getPassagewayList"
      @closed="reset"
    >
      <el-form :model="form" :rules="formRules" ref="form" label-width="90px">
        <el-form-item label="出入口">
          <el-select
            v-model="form.channelId"
            placeholder="请选择出入口"
            filterable
            clearable
          >
            <el-option
              v-for="item in passagewayList"
              :key="item.channelId"
              :label="item.channelName"
              :value="item.channelId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备名称">
          <el-input
            v-model="form.deviceName"
            placeholder="请输入设备名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="内网IP">
          <el-input
            v-model="form.localIpAddr"
            placeholder="请输入内网IP"
          ></el-input>
        </el-form-item>
        <el-form-item label="rtsp端口">
          <el-input
            v-model="form.rtspPort"
            placeholder="请输入rtsp端口"
          ></el-input>
        </el-form-item>
        <el-form-item label="显示屏类型">
          <el-radio-group v-model="form.screenType">
            <el-radio label="无"></el-radio>
            <el-radio label="LED"></el-radio>
            <el-radio label="LCD"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="音量">
          <el-slider
            v-model="form.soundVolume"
            :max="deviceType === '百胜摄像头' ? 9 : 100"
          ></el-slider>
        </el-form-item>
        <el-form-item label="用户名">
          <el-input
            v-model="form.username"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="form.password" placeholder="请输入密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["getList"],
  data() {
    return {
      show: false,
      loading: false,
      passagewayList: [],
      deviceType: "",
      form: {
        deviceRegisterId: "",
        channelId: "",
        localIpAddr: "",
        rtspPort: "",
        deviceName: "",
        screenType: "",
        soundVolume: 0,
        username: "",
        password: "",
      },
      formRules: {},
    };
  },
  methods: {
    async getPassagewayList() {
      try {
        let res = await this.$http.post("/channel/list/all", {});
        if (res.code === 0) {
          this.passagewayList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    reset() {
      this.$set(this, "form", {
        deviceRegisterId: "",
        channelId: "",
        localIpAddr: "",
        rtspPort: "",
        deviceName: "",
        screenType: "",
        soundVolume: 0,
        username: "",
        password: "",
      });
      this.$refs["form"].resetFields();
    },
    confirmBtn() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            let params = {};
            for (let key in this.form) {
              if (
                this.form[key] ||
                this.form[key] === 0 ||
                key === "deviceName"
              ) {
                params[key] = this.form[key];
              }
            }
            let res = await this.$http.post("/device/register/edit", params);
            if (res.code === 0) {
              this.getList();
              this.$message.success("编辑成功");
              this.show = false;
            } else {
              this.$message.error(res.msg);
            }
          } catch (err) {
            this.$message.error(err);
          } finally {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss">
#addVehicleDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-select {
          width: 100%;
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
