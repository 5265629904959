<template>
  <div id="issueInfoDialog-playback" v-loading="loading">
    <div class="container">
      <div class="fileList">
        <h1>文件列表</h1>
        <div v-for="(item, index) of list" :key="item.fileMdCode" class="item">
          <p @click="listClick(item, index, false)">{{ item.fileName }}</p>
          <i class="el-icon-delete" @click="deleteFile(item, index, false)"></i>
        </div>
        <el-upload
          :headers="{ ['Authorization']: 'Bearer ' + token }"
          :data="{ deviceRegisterId }"
          :action="BASE_URL + 'device/register/send/file/device'"
          :show-file-list="false"
          :before-upload="uploadBefore"
          :on-success="uploadSuccess"
          :on-error="uploadError"
        >
          <el-button type="primary" plain>上传文件</el-button>
        </el-upload>
      </div>
      <div class="playList">
        <h1>播放列表</h1>
        <Container @drop="onDrop" :remove-on-drop-out="true">
          <Draggable
            v-for="(item, index) of playList"
            :key="item.fileMdCode"
            class="item"
          >
            <p @click="listClick(item, index, true)">
              {{ item.fileName }}
            </p>
          </Draggable>
        </Container>
      </div>
    </div>
    <el-button type="primary" @click="saveList">保存设置</el-button>
  </div>
</template>

<script>
import { Container, Draggable } from "vue-smooth-dnd";
export default {
  components: { Container, Draggable },
  props: ["deviceRegisterId"],
  data() {
    return {
      loading: false,
      customMessage: "",
      BASE_URL: process.env.VUE_APP_BASE_URL,
      list: [],
      playList: [],
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }

      return result;
    },
    onDrop(dropResult) {
      this.playList = this.applyDrag(this.playList, dropResult);
    },
    uploadBefore(file) {
      const typeVerification =
        file.type === "image/jpeg" || file.type === "video/mp4";
      const sizeVerification = file.size / 1024 / 1024 < 10;

      if (!typeVerification) {
        this.$message.error("上传文件只能是 JPG、MP4 格式!");
        return false;
      }
      if (!sizeVerification) {
        this.$message.error("上传文件大小不能超过 10MB!");
        return false;
      }
      this.loading = true;
      return true;
    },
    uploadSuccess(res) {
      if (res.code === 0) {
        this.$message.success("上传成功");
        this.getList();
      } else {
        this.$message.error(res.msg);
      }
      this.loading = false;
    },
    uploadError() {
      this.$message.error("上传失败");
      this.loading = false;
    },
    listClick(row, index, isPlay) {
      if (!isPlay) {
        this.playList.push(row);
        this.list.splice(index, 1);
      } else {
        this.list.push(row);
        this.playList.splice(index, 1);
      }
      this.saveList();
    },
    async getList() {
      this.loading = true;
      const res = await this.$http.get(
        `/device/register/get/file/list/${this.deviceRegisterId}`
      );

      if (res.code === 0) {
        this.list = res.data.filter((item) => item.playFlag === "否");
        this.playList = res.data.filter((item) => item.playFlag === "是");
      } else {
        this.$message.error(res.msg);
      }
      this.loading = false;
    },
    async saveList() {
      this.loading = true;

      const params = new URLSearchParams();
      params.append("deviceRegisterId", this.deviceRegisterId);
      for (const item of this.playList) {
        params.append("deviceFileIds", item.deviceFileId);
      }

      const res = await this.$http.post(
        `/device/register/set/lcd/play`,
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (res.code === 0) {
        this.$message.success("操作成功");
        this.getList();
      } else {
        this.$message.error(res.msg);
      }
      this.loading = false;
    },
    deleteFile(row, index) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const params = new URLSearchParams();
        params.append("deviceRegisterId", this.deviceRegisterId);
        params.append("deviceFileIds", row.deviceFileId);
        let res = await this.$http.post(
          `/device/register/remove/remote/files`,
          params,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        if (res.code === 0) {
          this.$message.success("删除成功");
          this.list.splice(index, 1);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss">
#issueInfoDialog-playback {
  .container {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    & > div {
      flex: 1;
      h1 {
        margin-bottom: 20px;
      }
    }
    .fileList,
    .playList {
      .item {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
        @include overflow-ellipsis;
        &:last-child {
          margin-bottom: 0;
        }
        p {
          width: 100%;
          border: 1px solid #eee;
          padding: 10px 10px 5px 10px;
          margin-bottom: 5px;
          margin-right: 10px;
        }
      }
    }

    .playList {
      margin-left: 20px;
      .item {
        p {
          border: 1px dashed $main-color;
        }
      }
    }
  }
  .el-upload,
  .el-button {
    width: 100%;
  }
}
</style>
