<template>
  <div id="issueInfoDialog-voiceBroadcast">
    <div class="container">
      <el-select
        v-model="broadcastList"
        multiple
        filterable
        clearable
        placeholder="请选择播报内容"
        :multiple-limit="8"
        size="medium"
        :popper-append-to-body="false"
        @change="broadcastChange"
      >
        <el-option
          v-for="item in typeList"
          :key="item.label"
          :label="item.label"
          :value="item.label"
        >
        </el-option>
      </el-select>
      <div class="sequence">
        <h1>请排放播报顺序</h1>
        <div class="tagList">
          <Container @drop="onDrop" :remove-on-drop-out="true">
            <Draggable v-for="item of draggableList" :key="item">
              <el-tag class="draggable-item">{{
                item === "自定义"
                  ? customMessage
                    ? "自定义（" + customMessage + "）"
                    : item
                  : item
              }}</el-tag>
            </Draggable>
          </Container>
        </div>
      </div>
    </div>

    <el-button type="primary" @click="confirm">确认下发</el-button>
  </div>
</template>

<script>
import { Container, Draggable } from "vue-smooth-dnd";
export default {
  components: { Container, Draggable },
  props: ["deviceRegisterId", "issueType", "typeList", "screenType"],
  data() {
    return {
      broadcastList: [],
      draggableList: [],
      loading: false,
      customMessage: "",
    };
  },

  methods: {
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }

      return result;
    },
    onDrop(dropResult) {
      this.draggableList = this.applyDrag(this.draggableList, dropResult);
    },
    broadcastChange(list) {
      if (this.draggableList.length) {
        if (this.draggableList.length > list.length) {
          for (let item of this.draggableList) {
            if (list.indexOf(item) === -1) {
              this.draggableList.splice(this.draggableList.indexOf(item), 1);
              break;
            }
          }
        } else {
          const lastType = list[list.length - 1];
          if (lastType === "自定义") {
            this.$prompt("", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              inputPlaceholder: "请输入自定义内容",
            }).then(({ value }) => {
              this.customMessage = value;
            });
          }
          this.draggableList.push(lastType);
        }
      } else {
        this.draggableList = list;
      }
    },
    async confirm() {
      let list = [];
      for (let i = 0, len = this.draggableList.length; i < len; i++) {
        list.push({
          rowIndex: i,
          colInfo: [
            {
              colIndex: 0,
              customMessage:
                this.draggableList[i] === "自定义" ? this.customMessage : null,
              issueInfo: this.typeList.find(
                (item) => item.label === this.draggableList[i]
              ).value,
            },
          ],
        });
      }
      try {
        this.loading = true;
        let res = await this.$http.post("/device/register/issue/edit", {
          deviceRegisterId: this.deviceRegisterId,
          issueType: "2",
          rowInfo: list,
        });
        if (res.code === 0) {
          this.$message.success("操作成功");
          this.$emit("success");
        } else {
          this.$message.error(res.msg);
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#issueInfoDialog-voiceBroadcast {
  .container {
    margin-bottom: 20px;
    .el-select {
      width: 100%;
      .el-input__inner {
        height: 50px;
      }
    }
    .sequence {
      margin-top: 20px;

      h1 {
        margin-bottom: 10px;
      }

      .tagList {
        border: 1px dashed $main-color;
        padding: 10px 10px 5px 10px;
        .smooth-dnd-draggable-wrapper {
          &:last-child {
            margin-bottom: 0;
          }
          .el-tag {
            width: 100%;
            padding: 15px 0;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .el-button {
    width: 100%;
  }
}
</style>
