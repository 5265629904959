<template>
  <div id="deviceRegister">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>设备主动注册</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <span class="label">选择停车场:</span>
            <el-select
              v-model="filtrate.parkingLotId"
              placeholder="请选择"
              filterable
              clearable
              @change="parkingLotChange"
            >
              <el-option
                v-for="item in parkingLotList"
                :key="item.parkingLotId"
                :label="item.parkingLotName"
                :value="item.parkingLotId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">选择出入口:</span>
            <el-select
              v-model="filtrate.channelId"
              filterable
              clearable
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option
                v-for="item in passagewayList"
                :key="item.channelId"
                :label="item.channelName"
                :value="item.channelId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">服务节点:</span>
            <el-select
              v-model="filtrate.serverCode"
              filterable
              clearable
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option
                v-for="item in serverList"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">设备状态:</span>
            <el-select
              v-model="filtrate.loggedIn"
              filterable
              clearable
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option label="离线" value="否"> </el-option>
              <el-option label="在线" value="是"> </el-option>
            </el-select>
          </li>
          <li>
            <el-button type="primary" size="medium" @click="getList(1)"
              >查询</el-button
            >
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="deviceCode" label="设备编号" align="center">
        </el-table-column>
        <el-table-column prop="deviceName" label="设备名称" align="center">
        </el-table-column>
        <el-table-column prop="parkingLotName" label="停车场" align="center">
        </el-table-column>
        <el-table-column prop="channelName" label="出入口" align="center">
        </el-table-column>
        <el-table-column prop="channelNum" label="通道数量" align="center">
        </el-table-column>
        <el-table-column prop="ip" label="注册IP(端口)" align="center">
        </el-table-column>
        <el-table-column prop="deviceType" label="设备类型" align="center">
        </el-table-column>
        <el-table-column prop="soundVolume" label="音量" align="center">
        </el-table-column>
        <el-table-column prop="screenType" label="显示屏类型" align="center">
        </el-table-column>
        <el-table-column prop="user" label="用户名 - 密码" align="center">
        </el-table-column>
        <el-table-column prop="serverCode" label="服务节点" align="center">
        </el-table-column>
        <el-table-column prop="createAt" label="创建时间" align="center">
        </el-table-column>
        <el-table-column prop="loggedIn" label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.loggedIn === '是'" class="loggedIn"
              >在线</span
            >
            <span v-else class="offline">离线</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="issueInfo(scope.row)"
              :disabled="
                scope.row.screenType === '无' ||
                !scope.row.screenType ||
                !scope.row.channelId
              "
              v-if="buttonList.includes('deviceRegister-issueInfo')"
              >下发配置</el-button
            >
            <el-button
              type="text"
              @click="checkMonitor(scope.row)"
              v-if="
                buttonList.includes('deviceRegister-checkMonitor') &&
                scope.row.deviceType !== '百胜对讲机'
              "
              >查看监控</el-button
            >
            <el-button
              type="text"
              @click="edit(scope.row)"
              v-if="buttonList.includes('deviceRegister-edit')"
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="del(scope.row.deviceRegisterId)"
              v-if="buttonList.includes('deviceRegister-delete')"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
    <action-dialog ref="actionDialog" :getList="getList" />
    <issue-info-dialog ref="issueInfoDialog" :getList="getList" />
    <video-dialog ref="videoDialog" />
  </div>
</template>
<script>
import actionDialog from "./actionDialog.vue";
import issueInfoDialog from "./issueInfoDialog/issueInfoDialog.vue";
export default {
  components: {
    actionDialog,
    issueInfoDialog,
    videoDialog: () => import("@/components/videoDialog.vue"),
  },
  data() {
    return {
      passagewayList: [],
      serverList: [],
      filtrate: {
        keyword: "",
        parkingLotId: null,
        serverCode: null,
        channelId: null,
        loggedIn: null,
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  computed: {
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
  },
  created() {
    this.getList();
    this.getPassagewayList();
    this.getServerList();
  },
  methods: {
    checkMonitor(row) {
      this.$refs.videoDialog.deviceRegisterId = row.deviceRegisterId;
      this.$refs.videoDialog.title = row.channelName ?? row.deviceCode;
      this.$refs.videoDialog.show = true;
    },
    issueInfo(row) {
      this.$refs.issueInfoDialog.deviceRegisterId = row.deviceRegisterId;
      this.$refs.issueInfoDialog.screenType = row.screenType;
      this.$refs.issueInfoDialog.show = true;
    },
    parkingLotChange() {
      this.getPassagewayList();
      this.getList(1);
    },
    reset() {
      this.filtrate = {
        keyword: "",
        parkingLotId: null,
        channelId: null,
        channelId: null,
        loggedIn: null,
      };
      this.getPassagewayList();
      this.getList(1);
    },
    async getPassagewayList() {
      try {
        let res = await this.$http.post("/channel/list/all", {
          parkingLotId: this.filtrate.parkingLotId
            ? this.filtrate.parkingLotId
            : null,
        });
        if (res.code === 0) {
          this.passagewayList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    async getServerList() {
      try {
        let res = await this.$http.get("/device/register/server/list");
        if (res.code === 0) {
          this.serverList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    edit(row) {
      for (let key in this.$refs.actionDialog.form) {
        this.$refs.actionDialog.form[key] = row[key];
      }
      this.$refs.actionDialog.deviceType = row.deviceType;
      this.$refs.actionDialog.show = true;
    },
    async del(deviceRegisterId) {
      this.$confirm("此操作将永久删除该设备, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.delete(
            `/device/register/delete/${deviceRegisterId}`
          );
          if (res.code === 0) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/device/register/list", {
          pageNum: page,
          pageSize: this.table.pageSize,
          parkingLotId: this.filtrate.parkingLotId
            ? this.filtrate.parkingLotId
            : null,
          channelId: this.filtrate.channelId ? this.filtrate.channelId : null,
          serverCode: this.filtrate.serverCode
            ? this.filtrate.serverCode
            : null,
          loggedIn: this.filtrate.loggedIn ? this.filtrate.loggedIn : null,
          keyword: this.filtrate.keyword,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.createAt = this.$moment(item.createAt).format(
              "yyyy-MM-DD HH:mm:ss"
            );
            item.ip = `${item.deviceIp}:${item.devicePort}`;
            item.user = `${item.username} - ${item.password}`;
          }
          this.table.tableData = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#deviceRegister {
  .loggedIn,
  .offline {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1da05e;
    &::before {
      content: "";
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #1da05e;

      margin-right: 5px;
    }
  }
  .offline {
    color: #8d8d8d;
    &::before {
      background-color: #8d8d8d;
    }
  }
}
</style>
